import React from 'react';
import catloader from '../img/cat.gif';
import dwarfloader from '../img/dwarf.webp';
const Footer = () => {
  return (
    <footer id="footer" className="site-footer main-footer-one clear-fix">
      <div className="container">
        <div className="site-footer-main">
          <div className="site-branding">
            <div className="site-identity">
              <p className="site-title">
                Copyright © 2024 <a href="https://www.shorty-workshop.be/" rel="home">Shorty-Workshop</a> | Dev&Crafted by MrKey2B  <img src={dwarfloader} alt="Logo" style={{ height: '22px', marginRight: '10px' }} />
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
