import React, { useState } from 'react';
import { Card, CardContent, Typography, TextField, Button, Grid, Box, IconButton } from '@mui/material';
import { AttachFile } from '@mui/icons-material';
import Navbar from '../Navbars';
import Footer from '../Footer';
import './styles.css'; // Importer le fichier CSS pour les styles supplémentaires

const HomePage = () => {
  const [projectData, setProjectData] = useState({
    title: '',
    description: '',
    specifications: '',
    budget: '',
    deadline: '',
    ref: '',
    contact: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setProjectData({
      ...projectData,
      [name]: value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    try {
      const response = await fetch('https://logapp.api.mrapi.net/api/projects/request', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(projectData),
      });
  
      if (response.ok) {
        console.log('Projet soumis avec succès !');
        // Réinitialiser les données du projet après la soumission réussie
        setProjectData({
          title: '',
          description: '',
          specifications: '',
          budget: '',
          deadline: '',
          ref: '',
          contact: ''
        });
      } else {
        console.error('Erreur lors de la soumission du projet :', response.statusText);
      }
    } catch (error) {
      console.error('Erreur lors de la soumission du projet :', error);
    }
  };
  

  return (
    <div>
      <Navbar />
      <br />
      <Typography variant="h4" align="center" style={{ marginBottom: '20px', color: 'white' }}>
        Mon Projet Personnalisé - Demande en Ligne
      </Typography>
      <br />
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8} md={6}>
          <Card sx={{
            backgroundColor: "#ffffff",
            borderRadius: "15px",
            boxShadow: "0px 3.5px 5.5px rgba(0, 0, 0, 0.02)", // Ombre
            p: "22px",
            width: "100%",
            position: "relative",
            minWidth: "0px",
            wordWrap: "break-word",
            backgroundClip: "border-box",
            border: "2px solid #fdd000",
          }}>
            <CardContent>
              <Typography variant="h5" align="center" gutterBottom>
                Soumettre un Projet
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Titre du Projet"
                      name="title"
                      value={projectData.title}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Description/Détails"
                      name="description"
                      value={projectData.description}
                      onChange={handleChange}
                      multiline
                      fullWidth
                      margin="normal"
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Specifications"
                      name="specifications"
                      value={projectData.specifications}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Budget"
                      name="budget"
                      value={projectData.budget}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Références"
                      name="ref"
                      value={projectData.ref}
                      onChange={handleChange}
                      multiline
                      fullWidth
                      margin="normal"
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Deadline"
                      name="deadline"
                      type="date"
                      value={projectData.deadline}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="attachment"
                        multiple
                        type="file"
                      />
                      <label htmlFor="attachment">
                        <IconButton component="span">
                          <AttachFile />
                        </IconButton>
                      </label>
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        Pièces Jointes
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Contact"
                      name="contact"
                      value={projectData.contact}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                </Grid>
                <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                  Soumettre ma Demande
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default HomePage;
