//import React, { useState, useEffect } from 'react';
//import { Box, TextField, Button, Typography } from '@mui/material';
//import { useAuth } from '../../context/AuthContext';
import LayoutAdmin from '../layoutsadmin';
//import { useNavigate } from 'react-router-dom';

function DashBoardPage() {

    return (
        <>
        <LayoutAdmin>

        </LayoutAdmin>
        </>
    );
}

export default DashBoardPage;