import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import HomePage from './components/Pages/Home';
import LoginPage from './components/Pages/Login';
import Register from './components/Pages/Register';
import DashBoardPage from './components/Pages/DashBoard';
import ItemList from './components/Pages/ItemList';
import CreateItem from './components/Pages/CreateItem';

const App = () => {
  const { isAuthenticated } = useAuth();

  return (
    <AuthProvider>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/register" element={<Register />} />
        {isAuthenticated ? (
          <>
            <Route exact path="/home" element={<DashBoardPage />} />
            <Route exact path="/articles" element={<ItemList />} />
            <Route exact path="/articles/new" element={<CreateItem />} />
            <Route path="*" element={<Navigate to="/" />} />
          </>
        ) : (
          <Route path="*" element={<Navigate to="/login" />} />
        )}
      </Routes>
    </AuthProvider>
  );
};

export default App;
