import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import LayoutAdmin from '../layoutsadmin';
import { Link } from 'react-router-dom';

const ItemList = () => {
  const [itemList, setItemList] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await fetch('https://api.mariabird.net/SWS/merch');
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des articles');
        }
        const data = await response.json();
        setItemList(data);
      } catch (error) {
        console.error('Erreur lors de la récupération des articles', error);
      }
    };

    fetchItems();
  }, []);

  return (
    <LayoutAdmin>
      <Button variant="contained" component={Link} to="/articles/new">New Item</Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Identifiant</TableCell>
              <TableCell>Nom de l'article</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Coût</TableCell>
              <TableCell>Couleurs</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itemList.map(item => (
              <TableRow key={item.ID}>
                <TableCell>{item.ID}</TableCell>
                <TableCell>{item.Identifier}</TableCell>
                <TableCell>{item.NameItem}</TableCell>
                <TableCell>{item.DescriptionItem}</TableCell>
                <TableCell>
                  <img src={item.ImageItem} alt={item.NameItem} style={{ width: '50px' }} />
                </TableCell>
                <TableCell>${item.Cost}</TableCell>
                <TableCell>{item.ColorsItem}</TableCell>
                <TableCell>
                  <Button variant="outlined" component={Link} to={`/articles/edit?=${item.Identifier}`}>Edit</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </LayoutAdmin>
  );
};

export default ItemList;
