import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from '../img/g2.png';
import { VscAccount } from "react-icons/vsc";

const NavAdmin = () => {
  return (
    <AppBar position="static" style={{ backgroundColor: '#212327' }}>
      <Toolbar>
        <div className="site-header-main" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <div className="site-branding">
            <div className="site-identity" style={{ display: 'flex', alignItems: 'center' }}>
              <img src={logo} alt="Logo" style={{ height: '40px', marginRight: '10px' }} />
            </div>
            <Typography variant="body2" component="div" style={{ color: '#ffffff' }}>
              Une seule limite, votre imagination!
            </Typography>
          </div>
          <div className="right-head pull-right">
            <Button component={Link} to="/home" variant="text" style={{ color: '#ffffff' }}>Home</Button>
            <Button component={Link} to="/articles" variant="text" style={{ color: '#ffffff' }}>Articles</Button>
            <Button component={Link} to="/profile" variant="text" style={{ color: '#ffffff' }}>Profile</Button>
            <Button component={Link} to="/logout" variant="text" style={{ color: '#ffffff' }}>Logout</Button>
            <Link to="/profile" style={{ color: '#ffffff', textDecoration: 'none' }}>
                <VscAccount />
            </Link>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default NavAdmin;
