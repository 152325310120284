import React from 'react';
import { Box, Grid } from '@mui/material';
import NavAdmin from './NavAdmin';
import Footer from './Footer';
//import Sidebar from './Sidebars';

const LayoutAdmin = ({ children }) => {
    return (
        <Grid container>
            <Grid item xs={112}>
                <NavAdmin />
            </Grid>
            <Grid item container>
                <Grid item xs={10}>
                    <Box p={3}>{children}</Box>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Footer />
            </Grid>
        </Grid>
    );
};

export default LayoutAdmin;
