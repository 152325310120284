import React, { useState } from 'react';
import { TextField, Button, Typography, Chip, Autocomplete, Card, CardMedia, CardContent, Skeleton } from '@mui/material';
import LayoutAdmin from '../layoutsadmin';

const CreateItem = () => {
  const [formData, setFormData] = useState({
    Identifier: '',
    NameItem: '',
    DescriptionItem: '',
    ImageItem: '',
    Cost: '',
    ColorsItem: [],
  });

  const colorsMap = {
    Noir: '#000000',
    Acadia: '#2e2c2d',
    'Taupe Grey': '#968b95',
    'Honey Flower': '#623afa',
    Bunting: '#2f354d',
    'Kingfisher Daisy': '#603785',
    Siren: '#712d44',
  };

  const colors = ['Noir', 'Bunting', 'Kingfisher Daisy', 'Acadia', 'Taupe Grey', 'Honey Flower', 'Siren'];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleColorsChange = (_, newColors) => {
    setFormData(prevState => ({
      ...prevState,
      ColorsItem: newColors
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://api.mariabird.net/SWS/merch', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (!response.ok) {
        throw new Error('Erreur lors de la création de l\'article');
      }
      window.location.href = '/articles';
    } catch (error) {
      console.error('Erreur lors de la création de l\'article', error);
    }
  };

  return (
    <LayoutAdmin>
      <Typography variant="h4" gutterBottom>Create New Item</Typography>
      <div style={{ display: 'flex' }}>
        <form onSubmit={handleSubmit} style={{ marginRight: '20px' }}>
          <TextField
            name="Identifier"
            label="Identifier"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.Identifier}
            onChange={handleChange}
          />
          <TextField
            name="NameItem"
            label="Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.NameItem}
            onChange={handleChange}
          />
          <TextField
            name="DescriptionItem"
            label="Description"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.DescriptionItem}
            onChange={handleChange}
          />
          <TextField
            name="ImageItem"
            label="Image URL"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.ImageItem}
            onChange={handleChange}
          />
          <TextField
            name="Cost"
            label="Cost"
            type="number"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.Cost}
            onChange={handleChange}
          />
          <Autocomplete
            multiple
            fullWidth
            options={colors}
            value={formData.ColorsItem}
            onChange={handleColorsChange}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip label={option} {...getTagProps({ index })} style={{ backgroundColor: colorsMap[option] }} />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name="ColorsItem"
                label="Colors"
                variant="outlined"
                margin="normal"
              />
            )}
          />
          <Button type="submit" variant="contained" color="primary">
            Create
          </Button>
        </form>
        <div>
          <Card sx={{ maxWidth: 306.667, maxHeight: 469.133 }}>
            <CardMedia
                component="img"
                height="260"
                style={{ background: '#333333', objectFit: 'cover', height: '100%', borderRadius: '0 0 0 0' }}
                image={formData.ImageItem || undefined}
            />
            {!formData.ImageItem && (
                <Skeleton variant="rectangular" width={306.667} height={260} style={{ borderRadius: '8px 8px 0 0' }} />
            )}
            <CardContent style={{ position: 'relative' }}>
              <Typography gutterBottom variant="h5" component="div">
                {formData.NameItem || <Skeleton />}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {formData.DescriptionItem || <Skeleton />}
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{ marginBottom: '10px' }}>
                Prix: {formData.Cost || <Skeleton />} $
              </Typography>
              <div style={{ position: 'absolute', bottom: '5px', right: '10px', display: 'flex', alignItems: 'center' }}>
                {formData.ColorsItem ? (
                  formData.ColorsItem.map(color => (
                    <div
                      key={color}
                      style={{
                        width: '20px',
                        height: '20px',
                        borderRadius: '50%',
                        backgroundColor: colorsMap[color],
                        display: 'inline-block',
                        marginLeft: '5px'
                      }}
                    />
                  ))
                ) : (
                  <Skeleton variant="circular" width={20} height={20} style={{ marginRight: '5px' }} />
                )}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </LayoutAdmin>
  );
};

export default CreateItem;
