import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext(null);

function getCookie(name) {
  const cookieArr = document.cookie.split(';');
  for (let i = 0; i < cookieArr.length; i++) {
    const cookiePair = cookieArr[i].split('=');
    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
}

function setCookie(name, value, daysToExpire) {
  const maxAge = daysToExpire * 24 * 60 * 60;
  document.cookie = `${name}=${value}; path=/; max-age=${maxAge}; secure; samesite=strict`;
}

function deleteCookie(name) {
  setCookie(name, '', -1);
}

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const token = getCookie('ShortyWS_session');
    if (token) {
      setIsAuthenticated(true);
      // Fetch user details and set them
    }
  }, []);

  const login = async (token) => {
    setCookie('ShortyWS_session', token, 30); // Set the cookie when logging in
    setIsAuthenticated(true);
    setToken(token);
    // Fetch user details and set them
  };

  const logout = () => {
    deleteCookie('ShortyWS_session'); // Delete the cookie when logging out
    setIsAuthenticated(false);
    setToken(null);
    setUserDetails(null);
  };

  // Function to check if user is authenticated based on the presence of the cookie
  const checkAuthentication = () => {
    return !!getCookie('ShortyWS_session');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, userDetails, token, checkAuthentication }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);