import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import logo from '../img/g2.png';
import { VscAccount } from "react-icons/vsc";

const Navbar = () => {
  return (
    <AppBar position="static" style={{ backgroundColor: '#212327' }}>
      <Toolbar>
        <div className="site-header-main" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <div className="site-branding">
            <div className="site-identity" style={{ display: 'flex', alignItems: 'center' }}>
              <img src={logo} alt="Logo" style={{ height: '40px', marginRight: '10px' }} />
              <Typography variant="h6" component="div" style={{ color: '#ffffff', textDecoration: 'none' }}>
                <a href="https://www.shorty-workshop.be/" rel="home" style={{ color: '#ffffff', textDecoration: 'none' }}></a>
              </Typography>
            </div>
            <Typography variant="body2" component="div" style={{ color: '#ffffff' }}>
              Une seule limite, votre imagination!
            </Typography>
          </div>
          <div className="right-head pull-right">
          <a href="https://www.shorty-workshop.be/?page_id=22" style={{ color: '#ffffff', textDecoration: 'none' }}>
            <VscAccount />
            </a>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
