import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import Navbar from '../Navbars';
import Footer from '../Footer';
import { useNavigate } from 'react-router-dom';

function LoginPage() {
    const navigate = useNavigate();
    const { login } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        let timer;
        if (error) {
            timer = setTimeout(() => {
                setError('');
            }, 15000);
        }
        return () => clearTimeout(timer);
    }, [error]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');

        try {
            const response = await fetch('https://api.mariabird.net/SWS/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();

            if (response.ok) {
                document.cookie = `ShortyWS_session=${data.token}; path=/; max-age=3600; secure; samesite=strict`;
                login(data.token);
                navigate('/articles');
            } else {
                setError(data.message || 'Vérifiez vos identifiants et réessayez.');
            }
        } catch (error) {
            setError('Vérifiez vos identifiants et réessayez.');
        }
    };

    return (
        <>
            <Navbar />
            <br />
      <Typography variant="h3" align="center" style={{ marginBottom: '20px', color: 'white'  }}>
        Login
      </Typography>
      <br />
            <Box component="form" role="form" onSubmit={handleSubmit} maxWidth="400px" mx="auto" mt={4} p={2}>
                {error && (
                    <Box mb={2} bgcolor="error.main" color="error.contrastText" p={1} borderRadius={4}>
                        <Typography variant="body2">{error}</Typography>
                    </Box>
                )}
                <Box mb={2}>
                    <TextField
                        type="email"
                        label="Email"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                    />
                </Box>
                <Box mb={2}>
                    <TextField
                        type="password"
                        label="Password"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                    />
                </Box>
                <Box mt={4} mb={1}>
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                        LOGIN
                    </Button>
                </Box>
            </Box>
            <Footer />
        </>
    );
}

export default LoginPage;
